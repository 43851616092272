<template>
  <section>
    <header class="mb-xxl">
      <Heading class="mb-lg">
        {{ $t('faqArtist.title') }}
      </Heading>
      <nav>
        <ol>
          <li
            v-for="(sectionLink, index) in sectionsLinks"
            :key="`section-link-${index}`"
            class="mb-xs">
            <Heading
              :tag="'h3'"
              :level="'h4'"
              isUnderlined>
              <router-link :to="sectionLink.link">
                {{ `${index + 1}. ${sectionLink.label}` }}
              </router-link>
            </Heading>
          </li>
        </ol>
      </nav>
    </header>

    <ol>
      <li
        id="discover"
        class="mb-xxl">
        <section>
          <header>
            <Heading
              :level="'h2'"
              :tag="'h2'"
              class="mb-lg">
              {{ $t('faqArtist.discover.title') }}
            </Heading>
          </header>
          <ol :class="{ 'ml-lg': $mediaQueries.isDesktop }">
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.discover.whatIsBUA.title') }}
              </Heading>
              <Paragraph>
                {{ $t('faqArtist.discover.whatIsBUA.description') }}
              </Paragraph>
            </li>
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.discover.whyBUA.title') }}
              </Heading>
              <Paragraph class="mb-base">
                {{ $t('faqArtist.discover.whyBUA.description') }}
              </Paragraph>
              <Paragraph
                v-for="(perk, index) in 6"
                :key="`why-bua-perk-${index}`"
                class="mb-xxs ml-xs">
                - {{ $t(`faqArtist.discover.whyBUA.perks.${index + 1}`) }}
              </Paragraph>
            </li>
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.discover.artistsTypes.title') }}
              </Heading>
              <Paragraph>
                {{ $t('faqArtist.discover.artistsTypes.description') }}
              </Paragraph>
            </li>
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.discover.isBUAPaidSite.title') }}
              </Heading>
              <Paragraph>
                {{ $t('faqArtist.discover.isBUAPaidSite.description') }}
              </Paragraph>
            </li>
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.discover.howToCreateAccount.title') }}
              </Heading>
              <Paragraph>
                {{ $t('faqArtist.discover.howToCreateAccount.description') }}
              </Paragraph>
            </li>
          </ol>
        </section>
      </li>

      <li
        id="signup"
        class="mb-xxl">
        <section>
          <header>
            <Heading
              :level="'h2'"
              :tag="'h2'"
              class="mb-lg">
              {{ $t('faqArtist.signup.title') }}
            </Heading>
          </header>
          <ol :class="{ 'ml-lg': $mediaQueries.isDesktop }">
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.signup.whoCanSignup.title') }}
              </Heading>
              <Paragraph>
                {{ $t('faqArtist.signup.whoCanSignup.description') }}
              </Paragraph>
            </li>
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.signup.displayedName.title') }}
              </Heading>
              <Paragraph>
                {{ $t('faqArtist.signup.displayedName.description') }}
              </Paragraph>
            </li>
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.signup.phoneVerification.title') }}
              </Heading>
              <Paragraph>
                {{ $t('faqArtist.signup.phoneVerification.description') }}
              </Paragraph>
            </li>
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.signup.emailVerification.title') }}
              </Heading>
              <Paragraph>
                {{ $t('faqArtist.signup.emailVerification.description') }}
              </Paragraph>
            </li>
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.signup.multipleActivities.title') }}
              </Heading>
              <Paragraph>
                {{ $t('faqArtist.signup.multipleActivities.description') }}
              </Paragraph>
              <Paragraph class="mt-xs">
                {{ $t('faqArtist.signup.multipleActivities.hint') }}
              </Paragraph>
            </li>
          </ol>
        </section>
      </li>

      <li
        id="profile"
        class="mb-xxl">
        <section>
          <header>
            <Heading
              :level="'h2'"
              :tag="'h2'"
              class="mb-lg">
              {{ $t('faqArtist.completeProfile.title') }}
            </Heading>
          </header>
          <ol :class="{ 'ml-lg': $mediaQueries.isDesktop }">
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.completeProfile.howToCompleteProfile.title') }}
              </Heading>
              <Paragraph class="mb-base">
                {{ $t('faqArtist.completeProfile.howToCompleteProfile.description') }}
              </Paragraph>
              <Paragraph
                v-for="(step, index) in 6"
                :key="`complete-profile-step-${index}`"
                class="mb-xxs ml-xs">
                - {{ $t(`faqArtist.completeProfile.howToCompleteProfile.steps.${index + 1}`) }}
              </Paragraph>
            </li>
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.completeProfile.whatToComplete.title') }}
              </Heading>
              <Paragraph>
                {{ $t('faqArtist.completeProfile.whatToComplete.description') }}
              </Paragraph>
            </li>
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.completeProfile.tags.title') }}
              </Heading>
              <Paragraph>
                {{ $t('faqArtist.completeProfile.tags.description') }}
              </Paragraph>
            </li>
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.completeProfile.performanceDuration.title') }}
              </Heading>
              <Paragraph>
                {{ $t('faqArtist.completeProfile.performanceDuration.description') }}
              </Paragraph>
            </li>
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.completeProfile.performanceLocation.title') }}
              </Heading>
              <Paragraph>
                {{ $t('faqArtist.completeProfile.performanceLocation.description') }}
              </Paragraph>
            </li>
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.completeProfile.medias.title') }}
              </Heading>
              <Paragraph>
                {{ $t('faqArtist.completeProfile.medias.description') }}
              </Paragraph>
            </li>
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.completeProfile.faqList.title') }}
              </Heading>
              <Paragraph>
                {{ $t('faqArtist.completeProfile.faqList.description') }}
              </Paragraph>
            </li>
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.completeProfile.mandatoryFields.title') }}
              </Heading>
              <Paragraph class="mb-base">
                {{ $t('faqArtist.completeProfile.mandatoryFields.description') }}
              </Paragraph>
              <Paragraph
                v-for="(field, index) in 5"
                :key="`complete-profile-mandatory-field-${index}`"
                class="mb-xxs ml-xs">
                - {{ $t(`faqArtist.completeProfile.mandatoryFields.fields.${index + 1}`) }}
              </Paragraph>
            </li>
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.completeProfile.profileNotDisplayedInSearch.title') }}
              </Heading>
              <Paragraph class="mb-base">
                {{ $t('faqArtist.completeProfile.profileNotDisplayedInSearch.description') }}
              </Paragraph>
              <Paragraph
                v-for="(reason, index) in 3"
                :key="`complete-profile-profile-not-displayed-in-search-reason-${index}`"
                class="mb-xxs ml-xs">
                - {{ $t(`faqArtist.completeProfile.profileNotDisplayedInSearch.reasons.${index + 1}`) }}
              </Paragraph>
              <Paragraph class="mt-base">
                {{ $t('faqArtist.completeProfile.profileNotDisplayedInSearch.hint') }}
              </Paragraph>
            </li>
            <li
              id="equipement"
              class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.completeProfile.equipement.title') }}
              </Heading>
              <Paragraph class="mb-base">
                {{ $t('faqArtist.completeProfile.equipement.description') }}
              </Paragraph>
              <ul>
                <li class="mb-sm">
                  <Heading
                    :level="'h4'"
                    :tag="'h4'">
                    {{ $t('faqArtist.completeProfile.equipement.activities.dj.title') }}
                  </Heading>
                  <Paragraph
                    v-for="(djEquipement, index) in 7"
                    :key="`dj-equipement-${index}`">
                    <span class="mr-xxs">▪</span> {{ $t(`faqArtist.completeProfile.equipement.activities.dj.equipements.${index + 1}`) }}
                  </Paragraph>
                </li>
                <li class="mb-sm">
                  <Heading
                    :level="'h4'"
                    :tag="'h4'">
                    {{ $t('faqArtist.completeProfile.equipement.activities.singer.title') }}
                  </Heading>
                  <Paragraph
                    v-for="(singerEquipement, index) in 4"
                    :key="`singer-equipement-${index}`">
                    <span class="mr-xxs">▪</span> {{ $t(`faqArtist.completeProfile.equipement.activities.singer.equipements.${index + 1}`) }}
                  </Paragraph>
                </li>
                <li class="mb-sm">
                  <Heading
                    :level="'h4'"
                    :tag="'h4'">
                    {{ $t('faqArtist.completeProfile.equipement.activities.singerMusician.title') }}
                  </Heading>
                  <Paragraph
                    v-for="(singerMusicianEquipement, index) in 4"
                    :key="`singerMusician-equipement-${index}`">
                    <span class="mr-xxs">▪</span> {{ $t(`faqArtist.completeProfile.equipement.activities.singerMusician.equipements.${index + 1}`) }}
                  </Paragraph>
                </li>
                <li class="mb-sm">
                  <Heading
                    :level="'h4'"
                    :tag="'h4'">
                    {{ $t('faqArtist.completeProfile.equipement.activities.musician.title') }}
                  </Heading>
                  <Paragraph
                    v-for="(musicianEquipement, index) in 4"
                    :key="`musician-equipement-${index}`">
                    <span class="mr-xxs">▪</span> {{ $t(`faqArtist.completeProfile.equipement.activities.musician.equipements.${index + 1}`) }}
                  </Paragraph>
                </li>
                <li class="mb-sm">
                  <Heading
                    :level="'h4'"
                    :tag="'h4'">
                    {{ $t('faqArtist.completeProfile.equipement.activities.makeupArtist.title') }}
                  </Heading>
                  <Paragraph
                    v-for="(makeupArtistEquipement, index) in 2"
                    :key="`makeupArtist-equipement-${index}`">
                    <span class="mr-xxs">▪</span> {{ $t(`faqArtist.completeProfile.equipement.activities.makeupArtist.equipements.${index + 1}`) }}
                  </Paragraph>
                </li>
                <li class="mb-sm">
                  <Heading
                    :level="'h4'"
                    :tag="'h4'">
                    {{ $t('faqArtist.completeProfile.equipement.activities.circusArtist.title') }}
                  </Heading>
                  <Paragraph
                    v-for="(circusArtistEquipement, index) in 1"
                    :key="`circusArtist-equipement-${index}`">
                    <span class="mr-xxs">▪</span> {{ $t(`faqArtist.completeProfile.equipement.activities.circusArtist.equipements.${index + 1}`) }}
                  </Paragraph>
                </li>
                <li class="mb-sm">
                  <Heading
                    :level="'h4'"
                    :tag="'h4'">
                    {{ $t('faqArtist.completeProfile.equipement.activities.mentalist.title') }}
                  </Heading>
                  <Paragraph
                    v-for="(mentalistEquipement, index) in 4"
                    :key="`mentalist-equipement-${index}`">
                    <span class="mr-xxs">▪</span> {{ $t(`faqArtist.completeProfile.equipement.activities.mentalist.equipements.${index + 1}`) }}
                  </Paragraph>
                </li>
                <li class="mb-md">
                  <Heading
                    :level="'h4'"
                    :tag="'h4'">
                    {{ $t('faqArtist.completeProfile.equipement.activities.dancer.title') }}
                  </Heading>
                  <Paragraph>
                    <span class="mr-xxs">▪</span> {{ $t(`faqArtist.completeProfile.equipement.activities.dancer.equipements.1`) }}
                  </Paragraph>
                </li>
                <li class="mb-sm">
                  <Heading
                    :level="'h3'"
                    :tag="'h3'"
                    class="mb-xs">
                    {{ $t('faqArtist.completeProfile.equipement.noEquipementSignup.title') }}
                  </Heading>
                  <Paragraph>
                    {{ $t('faqArtist.completeProfile.equipement.noEquipementSignup.description') }}
                  </Paragraph>
                </li>
              </ul>
            </li>
          </ol>
        </section>
      </li>

      <li
        id="pricing"
        class="mb-xxl">
        <section>
          <header>
            <Heading
              :level="'h2'"
              :tag="'h2'"
              class="mb-lg">
              {{ $t('faqArtist.performancePricing.title') }}
            </Heading>
          </header>
          <ol :class="{ 'ml-lg': $mediaQueries.isDesktop }">
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.performancePricing.pricingMeaning.title') }}
              </Heading>
              <Paragraph class="mb-base">
                {{ $t('faqArtist.performancePricing.pricingMeaning.description') }}
              </Paragraph>
              <Paragraph
                v-for="(meaning, index) in 2"
                :key="`performance-pricing-meaning-${index}`"
                class="mb-xxs ml-xs">
                - {{ $t(`faqArtist.performancePricing.pricingMeaning.meanings.${index + 1}`) }}
              </Paragraph>
              <Paragraph class="mt-xs">
                {{ $t('faqArtist.performancePricing.pricingMeaning.hint') }}
              </Paragraph>
            </li>
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.performancePricing.howToSetPricing.title') }}
              </Heading>
              <Paragraph class="mb-base">
                {{ $t('faqArtist.performancePricing.howToSetPricing.description') }}
              </Paragraph>
            </li>
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.performancePricing.revenue.title') }}
              </Heading>
              <Paragraph class="mb-base">
                {{ $t('faqArtist.performancePricing.revenue.description') }}
              </Paragraph>
            </li>
          </ol>
        </section>
      </li>

      <li
        id="availabilities"
        class="mb-xxl">
        <section>
          <header>
            <Heading
              :level="'h2'"
              :tag="'h2'"
              class="mb-lg">
              {{ $t('faqArtist.availabilities.title') }}
            </Heading>
          </header>
          <ol :class="{ 'ml-lg': $mediaQueries.isDesktop }">
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.availabilities.perks.title') }}
              </Heading>
              <Paragraph class="mb-base">
                {{ $t('faqArtist.availabilities.perks.description') }}
              </Paragraph>
            </li>
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.availabilities.howToUpdateAvailabilities.title') }}
              </Heading>
              <Paragraph class="mb-base">
                {{ $t('faqArtist.availabilities.howToUpdateAvailabilities.description') }}
              </Paragraph>
            </li>
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.availabilities.whyUpdateAvailabilities.title') }}
              </Heading>
              <Paragraph class="mb-base">
                {{ $t('faqArtist.availabilities.whyUpdateAvailabilities.description') }}
              </Paragraph>
            </li>
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.availabilities.billing.title') }}
              </Heading>
              <Paragraph class="mb-base">
                {{ $t('faqArtist.availabilities.billing.description') }}
              </Paragraph>
            </li>
          </ol>
        </section>
      </li>

      <li
        id="account"
        class="mb-xxl">
        <section>
          <header>
            <Heading
              :level="'h2'"
              :tag="'h2'"
              class="mb-lg">
              {{ $t('faqArtist.myAccount.title') }}
            </Heading>
          </header>
          <ol :class="{ 'ml-lg': $mediaQueries.isDesktop }">
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.myAccount.howToFillInformations.title') }}
              </Heading>
              <Paragraph class="mb-base">
                {{ $t('faqArtist.myAccount.howToFillInformations.description') }}
              </Paragraph>
              <Paragraph
                v-for="(status, index) in 2"
                :key="`my-account-status-${index}`"
                class="mb-xxs">
                {{ $t(`faqArtist.myAccount.howToFillInformations.statuses.${index + 1}`) }}
              </Paragraph>
              <Paragraph
                class="mt-base">
                {{ $t('faqArtist.myAccount.howToFillInformations.hint') }}
              </Paragraph>
            </li>
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.myAccount.whyAccountBannedOrUnpublished.title') }}
              </Heading>
              <Paragraph class="mb-base">
                {{ $t('faqArtist.myAccount.whyAccountBannedOrUnpublished.description') }}
              </Paragraph>
              <Paragraph
                v-for="(reason, index) in 4"
                :key="`my-account-reason-${index}`"
                class="mb-xxs">
                {{ $t(`faqArtist.myAccount.whyAccountBannedOrUnpublished.reasons.${index + 1}`) }}
              </Paragraph>
            </li>
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.myAccount.forgotPassword.title') }}
              </Heading>
              <Paragraph class="mb-base">
                {{ $t('faqArtist.myAccount.forgotPassword.description') }}
              </Paragraph>
            </li>
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.myAccount.changedEmailOrTelephone.title') }}
              </Heading>
              <Paragraph class="mb-base">
                {{ $t('faqArtist.myAccount.changedEmailOrTelephone.description') }}
              </Paragraph>
            </li>
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.myAccount.howToModifyPersonalInformations.title') }}
              </Heading>
              <Paragraph class="mb-base">
                {{ $t('faqArtist.myAccount.howToModifyPersonalInformations.description') }}
              </Paragraph>
            </li>
          </ol>
        </section>
      </li>

      <li
        id="signin"
        class="mb-xxl">
        <section>
          <header>
            <Heading
              :level="'h2'"
              :tag="'h2'"
              class="mb-lg">
              {{ $t('faqArtist.signinIssues.title') }}
            </Heading>
          </header>
          <ol :class="{ 'ml-lg': $mediaQueries.isDesktop }">
            <li class="mb-md">
              <Heading
                :level="'h3'"
                :tag="'h3'"
                class="mb-xs">
                {{ $t('faqArtist.signinIssues.howToDeleteAccount.title') }}
              </Heading>
              <Paragraph class="mb-base">
                {{ $t('faqArtist.signinIssues.howToDeleteAccount.description') }}
              </Paragraph>
            </li>
          </ol>
        </section>
      </li>
    </ol>
  </section>
</template>

<script>

import Heading        from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph      from '../../../components/atoms/Paragraph/a-Paragraph.vue';


export default {
  name: 'p-ServiceFAQ',
  components: {
    Heading,
    Paragraph,
  },
  computed: {
    sectionsLinks() {
      return [
        {
          label: this.$t('faqArtist.discover.title'),
          link: '#discover',
        },
        {
          label: this.$t('faqArtist.signup.title'),
          link: '#signup',
        },
        {
          label: this.$t('faqArtist.completeProfile.title'),
          link: '#profile',
        },
        {
          label: this.$t('faqArtist.performancePricing.title'),
          link: '#pricing',
        },
        {
          label: this.$t('faqArtist.availabilities.title'),
          link: '#availabilities',
        },
        {
          label: this.$t('faqArtist.myAccount.title'),
          link: '#account',
        },
        {
          label: this.$t('faqArtist.signinIssues.title'),
          link: '#signin',
        },
      ];
    },
  }
}

</script>
